import axios from "axios"
import store from "@/store/index"
import router from "@/router"
export async function search_product(params: any) {
  store.dispatch('load_page', true)
  const api = await axios.post('/api/store/products/search', {
    group: params.group,
    search: params.search,
    limit: 12,
    page: params.page,
    sort_field: params.sort_field,
    sort_type: params.sort_type,
    prod_type: params.prod_type,
    has_quantity: params.has_quantity,
    tags: params.tags,
    min_price: params.min,
    max_price: params.max,
    tag_condition: params.tag_condition,
  })
  if (!api.data.error) {
    if (params.page != 1) {
      store.dispatch('see_more', api.data.Result)
      store.dispatch('load_page', false)
      return api
    }
    store.dispatch('result', api.data.Result)
    store.dispatch('load_page', false)
  }
  return api
}

export function search_product_tag(params: any) {
  router.push('/search')
  store.dispatch('load_page', true)
  axios.post('/api/store/products/search', params).then(Response => {
    store.dispatch('load_page', false)
    console.log(Response.data)
    if (!Response.data.error) {
      store.dispatch('result', Response.data.Result)
    }
  })
}

export function get_filters() {
  axios.post('/api/Store/Products/filters', {
  }).then(Response => {
    store.dispatch('filter_search_filds', Response.data.Result)
  })
}