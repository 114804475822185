import store from "@/store"
import axios from "axios"

export function categories_list(id: string) {
  const categoryTime = localStorage.getItem("category_time")
  const time = new Date()
  const now = time.getTime()
  if (categoryTime) {
    if ((parseInt(categoryTime) + 86400000) < now) {
      localStorage.removeItem('categories_list')
    }
  }
  const categories_list_items = localStorage.getItem('categories_list')
  let categories_list_item: any
  if (categories_list_items) {
    const cagetgories = JSON.parse(categories_list_items)
    for (const category of cagetgories) {
      if (category.id == id) {
        categories_list_item = category
      }
    }
  }
  if (categories_list_item) {
    store.dispatch('categories_list', categories_list_item.categorry)
  } else {
    store.dispatch('load_page', true)
  axios.post('/api/Store/ProductGroups/groupList',{
    group_id: id
  })
  .then(Response => {
    store.dispatch('load_page', false)
    let categories = [] as any
    if (categories_list_items) {
      categories = JSON.parse(categories_list_items)
    }
    categories.push({categorry: Response.data.Result, id: id})
    localStorage.setItem('categories_list', JSON.stringify(categories))
    localStorage.setItem('category_time', JSON.stringify(now))
      store.dispatch('categories_list', Response.data.Result)
  })
  }
}