<template>
<div>
  <v-card v-if="categories.length < 1" class="pa-3 card flat-card mx-2 my-2 category" color="surface">
    <v-row class="pa-1">
      <v-col cols="6" style="width: 150px; height:150px">
        <v-skeleton-loader
        v-bind="attrs"
        type="image"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader
        v-bind="attrs"
        type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="pa-1">
      <v-col cols="6">
        <v-skeleton-loader
        v-bind="attrs"
        type="image"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader
        v-bind="attrs"
        type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="pa-1">
      <v-col cols="6">
        <v-skeleton-loader
        v-bind="attrs"
        type="image"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader
        v-bind="attrs"
        type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-card>
  <v-card v-if="categories.length > 1" class="card pa-3 mx-2 flat-card" color="surface">
    <v-row class="pa-1">
      <v-col v-for="categorie in categories" :key="categorie.id" cols="6">
        <div @click="category_subordinate(categorie)" class="d-flex flex-column align-center justify-center">
          <v-avatar size="150" class="tile">
            <v-img :src="categorie.icon"></v-img>
          </v-avatar>
          <span class="medium-font mt-1 font-weight-bold onSurface--text" v-text="categorie.title"></span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</div>
</template>
<script>
import { categories_list } from '../models/categories'
import { mapState } from 'vuex'
import store from '@/store'
import { search_product_tag } from '@/views/shop/search/models/search';
import router from '@/router'
export default {
    computed: {
    ...mapState({
      categories: state => state.categories.categories,
      loading: state => state.search.load_page,
    })
  },
  created () {
    localStorage.removeItem('filter_search')
      categories_list('')
  },
  methods: {
    category_subordinate (category) {
      localStorage.setItem('category_id', JSON.stringify(category))
      this.$router.push('/subordinate')
    }
  } 
}
</script>